<script setup lang="ts">
// This is a wrapper around BaseListbox that makes it easier to use within our vee-validate forms.
// It also adds a default factory to work with objects that have an id property easily. This
// way you can pass in an array of ApiEntity[] as `items` and avoid having to write lots of boilerplate code.
//
// TODO: make the props/API for this more similar to FromAutocompleteField. Maybe even combine the two components?

type ItemType = any

const props = withDefaults(
  defineProps<{
    name: string
    // TODO: make this work with { value: 'id', label: 'label' }
    // Either an array of items or a function that returns a promise that resolves to an array of items.
    // We expect the function to return a `data` prop that is an array of **deserialized** items (through deserializeJsonApiData).
    items: ItemType | (() => Promise<{ data: ItemType[] }>)
    // Include a blank/null option at the top of the list
    includeNull?: boolean
    label?: string
    icon?: string
    multiple?: boolean
    keepValue?: boolean
    placeholder?: string
    isSubmitting?: boolean
    cols?: number
    labelFactory?: (value: ItemType) => string
  }>(),
  {
    includeNull: false,
    apiEndpoint: undefined,
    label: undefined,
    icon: undefined,
    placeholder: undefined,
    multiple: false,
    keepValue: false,
    isSubmitting: false,
    cols: 12,
    labelFactory: undefined,
  },
)

const emits = defineEmits<{
  // Emits the full object(s)
  // TODO: only ApiEntity or also other types?
  (event: 'on-select', value: ItemType | ItemType[]): void
}>()

const { value, handleChange, errorMessage } = useField<ItemType>(props.name)

const allItems = ref<ItemType[]>(Array.isArray(props.items) ? props.items : [])

const loading = ref(false)

// If items is an async function, call it and store the result in a ref
async function fetchItems() {
  if (typeof props.items === 'function') {
    loading.value = true
    // TOOD: error handling, etc.
    const { data } = await props.items()
    allItems.value = data.value
  }
  else {
    allItems.value = props.items
  }
  loading.value = false
}

// Fetch items when the component is mounted (and when the props change)
watch(() => props.items, async () => {
  await fetchItems()
  // console.log(`allItems initialized with:`, allItems.value)
}, { immediate: true })

// Utility function to check if an item is an object with an id property
const hasIdProperty = (item: any): boolean => {
  return typeof item === 'object' && item !== null && 'id' in item
}

// Default label factory function that handles both objects and plain values
// This is not the best solution ever, I'm just trying random properties here (name, title, label).
// It would be better to be able to pass in a prop, or use actual types like NamedApiEntity.
// that would also make it possible to remove the `any` return type.
const defaultLabelFactory = (value?: ItemType) => {
  // TODO: add an option for a blank label?
  if (!value && props.includeNull) return 'Maak een keuze'

  // TODO: do we want this?
  // If value is not an object with an id property, return the value itself
  if (!hasIdProperty(value)) return value

  // Find the option in the items list by its id
  const option = allItems.value.find(option => option.id == value.id) // as any

  // If option is not found, return the value itself
  if (!option) return value

  // Return the first non-null label property from the option
  return option.name || option.title || option.label || value
}

// Wrapper function to create a label factory that transforms id to item if item has an
// id property.
const createItemLabelFactory
  = (factory: (item: ItemType) => string) => (value: any) => {
    // If items are objects with an id property, transform the value from id to the corresponding item
    if (allItems.value.length > 0 && hasIdProperty(allItems.value[0])) {
      const item = allItems.value.find((option: any) => option.id === value)
      return factory(item)
    }

    // If items are not objects with an id property, use the factory directly on the value
    return factory(value)
  }

const wrappedLabelFactory = createItemLabelFactory(
  props.labelFactory || defaultLabelFactory,
)

// Map the selected object to the value we want to store in the field: an id
function mapValue(value: id | ItemType | null) {
  return allItems.value.find((option: any) => option?.id === value)
}

// value is either an id or an object, or an array of ids or objects
function onUpdate(value: any) {
  handleChange(value)

  if (allItems.value.length > 0 && hasIdProperty(allItems.value[0])) {
    // We want to map the id back to the original object(s)
    const mapped = Array.isArray(value) ? value.map(mapValue) : mapValue(value)
    emits('on-select', mapped)
  }
  else {
    // Otherwise, we just emit the value as is
    emits('on-select', value)
  }
}

const allItemsWithBlankOption = computed(() => {
  if (props.includeNull) {
    return [null, ...allItems.value]
  }
  // TODO: fix crash when allItems is not an array (due to `allItemsWithBlankOption?.map` below)
  return allItems.value
})

// const type = 'radio'

</script>

<template>
  <div :class="`col-span-${cols}`">
    
    <BasePlaceload v-if="loading" />
    <BaseListbox
      v-else
      :items="allItemsWithBlankOption?.map((i: any) => hasIdProperty(i) ? i.id : i)"
      :label="label"
      :error="errorMessage"
      :disabled="isSubmitting"
      :properties="{
        label: wrappedLabelFactory,
      }"
      :placeholder="placeholder"
      :multiple="multiple"
      :keep-value="keepValue"
      :model-value="value"
      @update:model-value="onUpdate"
    />
  </div>
</template>
